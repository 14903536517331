import * as React from "react"
import styled from "styled-components"
import { StringParam, useQueryParams } from "use-query-params"

let interval: NodeJS.Timer

const RedirectPage = () => {
  const [counter, setCounter] = React.useState(3)
  const [query] = useQueryParams({
    code: StringParam,
  })
  const { code: codeParam } = query

  React.useEffect(() => {
    interval = setInterval(() => {
      setCounter(counter => counter - 1)
    }, 1000)
    return () => clearInterval(interval)
  }, [])

  React.useEffect(() => {
    if (counter <= 0) {
      clearInterval(interval)
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage(codeParam)
      }
    }
  }, [counter])

  return (
    <Container>
      <Text>You are successfully logged in</Text>
      {counter > 0 ? (
        <Text>You will be redirected to the App in {counter} seconds</Text>
      ) : (
        <Text>Redirecting...</Text>
      )}
    </Container>
  )
}

const Text = styled.p`
  margin-bottom: 40px;
  padding: 0 20px;
  color: ${({ theme }) => theme.color.black};
  font-weight: 600;
  font-size: 20px;
`

const Container = styled.div`
  text-align: center;
`

export default RedirectPage
